@font-face {
    font-family: 'IBM Plex Mono';
    src: url('IBMPlexMonoRegular.ttf') format('truetype');
}

@font-face {
    font-family: 'IBM Plex MonoLight';
    src: url('IBMPlexMonoLight.ttf') format('truetype');
}

@font-face {
    font-family: 'IBM Plex MonoExtraLight';
    src: url('IBMPlexMonoExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'IBM Plex MonoBoldItalic';
    src: url('IBMPlexMonoBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'IBMPlexMonoItalic';
    src: url('IBMPlexMonoItalic.ttf') format('truetype');
}

@font-face {
    font-family: Montserrat;
    src: url('Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 500 700;
    font-style: normal;
}