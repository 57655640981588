body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.abstraction-wrapper {
  height: 150px;
  width: 100%;
  position: relative;
  margin-top: 64px
}

.abstraction-canvas {
  transform: rotate(270deg);
  transform-origin: top left;
  position: absolute;
  top: 150px;
  left: 0;
  right: 0;
}

@media (max-width: 767px) {
  .abstraction-wrapper {
    height: 80px;
    margin-top: 32px
  }
  .abstraction-canvas {
    top: 80px;
  }
}

.MuiPagination-ul li button {
  border-radius: 0;
  background: white;
  border-color: black;
  min-width: 28px;
  height: 28px;
}

.MuiPagination-ul li button:not(.Mui-selected):hover {
  background: black;
  color: white;
}

.MuiPagination-ul li button.Mui-selected {
  background: black;
  color: white;
}

@media (min-width: 350px) {
  .MuiPagination-ul li button {
    min-width: 32px;
    height: 32px;
  }
}
